<template>
  <v-container>
    <v-card  class="ma-1">
        <v-card-title>
            Nutzerprofil: {{$store.state.idTokenParsed.name}}
            <v-spacer/>
            <v-switch v-model="showUserDetail" label="Detailansicht"></v-switch>
        </v-card-title>
        <v-data-table
            v-if="showUserDetail"
            :headers="headers"
            :items="items"
            item-key="name"
        ></v-data-table>
    </v-card>
    <v-card class="ma-1">
        <v-card-title>
            Aktive Microservices
            <v-spacer/>
            <v-btn icon @click="getCurrentServices()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card flat v-for="service in active_services" :key="service.id">
            <v-card-title>{{service.id}}</v-card-title>
            <v-card-subtitle>{{service.description}}</v-card-subtitle>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="predictShared(service.id)">Vorhersagen (geteilt)</v-btn>
                <v-btn text @click="predictPrivate(service.id)">Vorhersagen (privat)</v-btn>
                <v-btn text @click="train(service.id)">Trainieren</v-btn>
            </v-card-actions>
        </v-card>
        <v-card-text v-if="active_services.length == 0">Derzeit keine Microservices aktiv!</v-card-text>
    </v-card>
    <v-card class="ma-1">
        <v-card-title>
            Aktive Jobs
            <v-spacer/>
            <v-btn icon @click="getCurrentServices()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card flat v-model="active_jobs" v-for="(job, index) in active_jobs" :key="job.jobNumber">
            <v-card-title>
                {{job.jobNumber}} 
                <v-spacer/>
                <v-btn v-if="job.isDone" icon @click="deleteJob(index, job.jobNumber)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle v-if="job.startedAt != ''">
                On Service: {{job.service}} at Timestamp: {{job.startedAt}}
            </v-card-subtitle>
            <v-card-subtitle v-else>
                On Service: {{job.service}}
            </v-card-subtitle>
            <v-card-text>
                {{job.status}}
            </v-card-text>
        </v-card>
        <v-card-text v-if="active_jobs.length == 0">Derzeit keine Jobs aktiv!</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '../store/index'

@Component
export default class HomeView extends Vue{
    // Don't change the name 'https://ki-gateway.tech4comp.dbis.rwth-aachen.de/gtw' it will be replaced on runtime by environment variables
    GATEWAY_BACKEND_HOST = 'https://ki-gateway.tech4comp.dbis.rwth-aachen.de/gtw'

    headers = [
        {text: 'Key', value: 'k'},
        {text: 'Value', value: 'v'},
    ]
    items = []
    showUserDetail = false

    servicePollIntervall = null

    active_services = []
    active_jobs = []

    mounted() {
        // Write User ID into Store
        var json = Vue.$keycloak.idTokenParsed
        store.commit('setIdToken', json)
        this.item = []
        for (var key in json) {
            this.items.push({k: key, v: json[key]})
        }
        this.items.push({k: 'token', v: Vue.$keycloak.token})

        // fetch active services
        this.getCurrentServices()

        // fetch current jobs
        this.getCurrentJobs()

        this.servicePollIntervall = setInterval(() => this.getCurrentServices(), 1000)
    }

    async getCurrentServices() {
        const requestOptions = {
            method: "GET",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token} 
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/services', requestOptions)
        this.active_services = await response.json();
    }

    async getCurrentJobs() {
         const requestOptions = {
            method: "GET",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token}
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/status/', requestOptions)
        this.active_jobs = await response.json()
    }


    async train(serviceName) {
        const requestOptions = {
            method: "POST",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token} 
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/train/private/' + serviceName, requestOptions)
        var job = await response.json()
        var ret_job = {
            jobNumber: job.job_number,
            pollInterval: setInterval(() => this.pollJobStatus(job.job_number), 500)
        }
        this.active_jobs.push(ret_job)
        this.pollJobStatus(job.job_number)
    }

    async predictPrivate(serviceName) {
        const requestOptions = {
            method: "POST",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token} 
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/predict/private/' + serviceName, requestOptions)
        var job = await response.json()
        var ret_job = {
            jobNumber: job.job_number,
            pollInterval: setInterval(() => this.pollJobStatus(job.job_number), 500)
        }
        this.active_jobs.push(ret_job)
        this.pollJobStatus(job.job_number)
    }

    async predictShared(serviceName) {
        const requestOptions = {
            method: "POST",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token} 
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/predict/shared/' + serviceName, requestOptions)
        var job = await response.json()
        var ret_job = {
            jobNumber: job.job_number,
            pollInterval: setInterval(() => this.pollJobStatus(job.job_number), 500)
        }
        this.active_jobs.push(ret_job)
        this.pollJobStatus(job.job_number)
    }

    async pollJobStatus(jobNumber) {
        const requestOptions = {
            method: "GET",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token} 
        };
        const response = await fetch(this.GATEWAY_BACKEND_HOST + '/status/' + jobNumber, requestOptions)

        for (var job of this.active_jobs) {
            if (job.jobNumber == jobNumber) {
                var status = await response.json()
                
                job.status = status.status
                job.isDone = status.isDone
                job.service = status.service
                job.startedAt = status.startedAt

                if (status.isDone) {
                    clearInterval(job.pollInterval)
                }
            }
        }
    }

    async deleteJob(index, jobNumber) {
        const requestOptions = {
            method: "GET",
            headers: {"Authorization": 'Bearer ' + Vue.$keycloak.token}
        };
        // retrieve result to delete job
        await fetch(this.GATEWAY_BACKEND_HOST + '/result/' + jobNumber, requestOptions)

        // remove from local list
        this.active_jobs.splice(index, 1)
    }

    beforeDestroy() {
        clearInterval(this.servicePollIntervall)
    }
}
</script>
